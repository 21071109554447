import React from 'react'
import { Input } from '../components/Input'
import { Textarea } from '../components/Textarea'

function TermsConditionsPage() {
  return (
    <>
      <div className='rules'>
        <div className='container'>
          <div className='rules__inner'>
            <h2>Terms & Conditions</h2>
            <div className='rules-info'>
              <div className='rules-info-text'>
                <span>AGREEMENT</span>
                <p>
                  These Terms and Conditions, together with our Privacy
                  Policy and Returns Policy, apply to your use of Cardly’s
                  ("we", "our" or "us") website located at
                  https://www.cardly.net ("Site").
                </p>
                <p>
                  By browsing or using the Site, you agree that these Terms and
                  Conditions, together with our Privacy Policy and Returns
                  Policy (collectively, the "Agreement") are binding on you and
                  an agreement is formed between us and you. If you do not agree
                  with the terms of the Agreement, you must not access, browse
                  or use this Site and refrain from making an Order.
                </p>
              </div>

              <div className='rules-info-text'>
                <ol className='rules-info-text-list'>
                  <li>
                    INTERPRETATION
                    <ol>
                      <li>
                        In this Agreement:
                        <ol>
                          <li>"Agreement" has the meaning in clause 1.2;</li>
                          <li>
                            "Australian Consumer Law" means Schedule 2 of the
                            Competition and Consumer Act 2010 (Cth);
                          </li>
                          <li>
                            "Business Day" means 9:00am – 5:00pm Monday to
                            Friday, excluding Saturdays, Sundays and public
                            holidays in Queensland, Australia;
                          </li>
                          <li>
                            "Business Customer" means a business that has
                            applied to an account
                            via and has
                            had such account approved and authorised by Cardly.
                          </li>
                          <li>
                            "Consumer Guarantee" has the meaning given in
                            Division 1 of Part 3-2 of the Consumer Law;
                          </li>
                          <li>
                            "Customer" means the person or legal entity issuing
                            a valid Order;
                          </li>
                          <li>
                            "Delivery Cost" means the costs associated with
                            delivery and handling of Products or Services as
                            specified in an Order, including, without
                            limitation, transport, freight or shipping charges,
                            insurance costs, import and export taxes and duties;
                          </li>
                          <li>
                            "Intellectual Property" means all present and future
                            intellectual or industrial property rights (whether
                            or not registered) throughout the world, including,
                            without limitation, in respect of patents, patent
                            applications, patentable inventions, copyright,
                            moral rights, trade names, trade marks, logos,
                            systems, circuit layout, designs, software, plant
                            breeder’s rights, domain names, trade secrets and
                            confidential information, including modifications
                            and improvements to same;
                          </li>
                          <li>
                            "Material" means any information, data, source
                            codes, drawings, content, text or images in any form
                            (whether visible or not), audio recordings, video
                            recordings, lists, sound, video, links, layout, look
                            and feel, control features and interfaces contained
                            on the Site, or otherwise displayed, uploaded or
                            published on, or via, the Site;
                          </li>
                          <li>
                            "Order" means an offer made by a Customer on, or
                            via, the Site in accordance with the terms of this
                            Agreement;
                          </li>
                          <li>
                            "Products" means the products, goods or items listed
                            or advertised on the Site for sale or otherwise;
                          </li>
                          <li>
                            "Services" means the services listed or advertised
                            on the Site for sale or otherwise;
                          </li>
                          <li>
                            "you" or "your" means the person or entity
                            accessing, using or relying upon the Site and
                            includes, when applicable.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Any reference in this Agreement to the singular includes
                        the plural, to any gender includes all genders, to any
                        act or statute includes any Act or statute which
                        supersedes, replaces or modifies any earlier Act or
                        statute, to persons includes all bodies and associations
                        both corporate and incorporated and vice versa.
                        Paragraph headings are for reference purposes only and
                        all references to clauses are to clauses in this
                        Agreement unless otherwise specified.
                      </li>
                    </ol>
                  </li>
                  <li>
                    SITE USE
                    <ol>
                      <li>
                        To become a member of the Site you must open a
                        membership account with us ("Account") by providing your
                        name, a valid email address and nominating a password
                        ("Password"). By purchasing any of the Products and
                        Services listed or advertised on the Site, you
                        automatically open an Account. Account registration is
                        free. If you do not provide accurate and complete
                        details we may not be able to activate your membership,
                        supply Products or provide the Services to you.
                      </li>
                      <li>
                        You will receive an email confirming registration with
                        us shortly after you have created your Account via the
                        Site.
                      </li>
                      <li>
                        You may not use one email address to register for
                        multiple Accounts. You must not hold more than one
                        Account at the same time.
                      </li>
                      <li>
                        You warrant and represent that your access to, or use
                        of, the Site is not unlawful or prohibited by any laws
                        which apply to you. You understand and agree that any
                        suspected fraudulent, abusive or illegal activity may be
                        referred to appropriate law enforcement authorities.
                      </li>
                      <li>
                        You represent, warrant and agree that you will not:
                        <ol>
                          <li>
                            Post unlawful, harassing, obscene, pornographic,
                            sexually explicit or indecent, vulgar, suggestive,
                            violent, threatening, hateful, offensive,
                            discriminatory, bigoted, abusive, inflammatory,
                            invasive of privacy or publicity rights, fraudulent,
                            deceptive or otherwise objectionable User Content;
                          </li>
                          <li>Post any sexually explicit User Content;</li>
                          <li>
                            Use language or user conduct that could constitute a
                            criminal offence, give rise to civil liability, or
                            otherwise violate any applicable local, state,
                            national, or international law or regulation;
                          </li>
                          <li>
                            Post any unsolicited or unauthorised advertising,
                            "spam," or junk mail, including "chain messages" and
                            "pyramid schemes";
                          </li>
                          <li>
                            Submit any User Content that is alleged to infringe
                            any patent, trademark, trade secret, copyright, or
                            other proprietary right or intellectual property;
                          </li>
                          <li>
                            Post User Content originally posted by another user
                            without permission or attribution;
                          </li>
                          <li>
                            Submit User Content designed to deceive or trick a
                            user of the Site;
                          </li>
                          <li>
                            Submit material that impersonates any person/entity
                            or otherwise misrepresents your affiliation with a
                            person/entity;
                          </li>
                          <li>
                            Supply unauthorised private information of any
                            third-party, including but not limited to addresses,
                            phone numbers, email addresses or credit card
                            numbers;
                          </li>
                          <li>
                            Submit any misleading, inaccurate or false personal
                            information;
                          </li>
                          <li>
                            Imply that you have any connection with us or that
                            we have endorsed you or any products/services for
                            any purpose;
                          </li>
                          <li>Modify, adapt, hack or emulate the Site;</li>
                          <li>
                            Use any interface not provided by us to access the
                            Site or to extract data;
                          </li>
                          <li>
                            Develop any third-party applications that interact
                            with User Content or the Site without our prior
                            consent;
                          </li>
                          <li>
                            Avoid or attempt to avoid any filtering, security
                            measures or other features designed to protect the
                            Site or third-parties;
                          </li>
                          <li>
                            Infringe upon/violate the rights of Cardly, our
                            users or any third-party; or
                          </li>
                          <li>
                            Permit viruses, corrupted data or other harmful,
                            disruptive or destructive files or code, script or
                            other software designed to automate any
                            functionality on the Service.
                          </li>
                        </ol>
                      </li>

                      <li>
                        You agree that you have sole responsibility for any
                        activity that occurs on or using your Account. You agree
                        to notify us immediately if you become aware of any
                        security breach or any unauthorised use of your Password
                        or Account.
                      </li>
                      <li>
                        We may, in our absolute discretion, terminate your
                        Account, disable your Account or restrict your access to
                        the Site (temporarily or permanently) where you have
                        breached the Agreement or for any reasonable cause and
                        at any time. Under these circumstances, you may be
                        prevented from accessing all or parts of the Site, your
                        Account details or any other content associated with
                        your Account. We will not be liable to you or any third
                        party if this occurs. We may impose limits or
                        restrictions on the use you may make of the Site.
                        Further, we may, for any reason, at any time and without
                        notice to you, withdraw the Site, or change or remove
                        Site functionality.
                      </li>
                      <li>
                        The Site may contain links to third party websites. Any
                        links to such websites provided on the Site are for
                        convenience only. We do not represent that we have any
                        relationship with any linked websites nor recommend or
                        endorse any goods, services or third party content
                        appearing on, or via, other websites linked to this
                        Site. We are not responsible for any loss or damage that
                        may arise from your access to, and/or use of, third
                        party websites, products and services. Additionally, we
                        are not responsible for the content or privacy practices
                        associated with linked websites. You should make your
                        own enquiries before using and/or accessing third party
                        websites.
                      </li>
                      <li>
                        You may not use the Site other than for its intended
                        purpose. You agree that you will not engage in any
                        activity that interferes with or disrupts the Site or
                        the servers and networks that host the Site. You agree
                        not to, circumvent, disable or otherwise interfere with
                        security-related features of the Site or attempt to do
                        so or otherwise interfere with, or restrict, any person
                        or visitor from accessing or using the Site.
                      </li>
                      <li>
                        Unless otherwise expressly stipulated in this Agreement,
                        you must not copy, adapt, distribute, display, reproduce
                        or transmit any content displayed or published on the
                        Site. 
                      </li>
                      <li>
                        You must take your own precautions to ensure that the
                        telecommunications equipment and computer systems used
                        by you to access and use the Site does not expose your
                        telecommunications equipment and computer systems to any
                        viruses, malicious computer code or other forms of
                        interference which may damage your computer system. We
                        accept no responsibility for any loss or damage to you
                        or anyone else which may arise out of, or in connection
                        with, your access to, and use of, the Site.
                      </li>
                    </ol>
                  </li>
                  <li>
                    LEGAL CAPACITY
                    <ol>
                      <li>
                        You must be eighteen (18) years of age or over to
                        register as a member of the Site or to Order and/or
                        purchase Products or Services on, or via, the Site. If
                        you are under the age of 18 years ("Minor"), you must
                        immediately cease accessing and using the Site unless
                        you have permission from a parent or guardian to create
                        an Account in accordance with clause 4.3 of this
                        Agreement. If you are found to be a Minor, we are
                        entitled, at our absolute discretion, to cancel or
                        terminate any Order and/or purchase of Products or
                        Services made on, or via, the Site.
                      </li>
                      <li>
                        Any Order and/or purchase made by you using this Site
                        and your continued use of the Site is an acknowledgement
                        by you that:
                        <ol>
                          <li>
                            you are over the age of eighteen (18) years, or have
                            obtained the relevant permission from a parent or
                            guardian to create an Account; and
                          </li>
                          <li>
                            you accept the Agreement and agree that you have
                            entered into a binding legal contract with us in
                            relation to the Agreement.
                          </li>
                        </ol>
                      </li>
                      <li>
                        If you are a parent or guardian permitting a Minor to
                        create an Account, you agree to:
                        <ol>
                          <li>
                            exercise supervision over the Minor’s use of the
                            Site;
                          </li>
                          <li>
                            assume all risks associated with use of the Site as
                            outlined in this Agreement;
                          </li>
                          <li>
                            ensure that all content and information that the
                            Minor may encounter on the Site is suitable and
                            appropriate for the Minor;
                          </li>
                          <li>
                            assume all liabilities resulting from the Minor’s
                            use of our website and their Account;
                          </li>
                          <li>
                            ensure the accuracy and truthfulness of all
                            information submitted by the Minor;
                          </li>
                          <li>
                            provide the consents contained in this Agreement on
                            behalf of the Minor.
                          </li>
                        </ol>
                      </li>
                      <li>
                        We may, at any time, request written confirmation from a
                        parent or guardian that you have permission to access
                        and use this Site.
                      </li>
                      <li>
                        We reserve the right to take legal action and/or seek
                        compensation for any loss or damage we may suffer as a
                        result of, or in connection with, any transaction
                        entered into by a Minor or from the parent or guardian
                        of a Minor who causes an Order to be placed.
                      </li>
                    </ol>
                  </li>
                  <li>
                    PRICING
                    <ol>
                      <li>
                        All prices listed on the Site are in Canadian Dollars
                        and include tax (where applicable or otherwise
                        specified). Pricing includes standard postage (unless
                        otherwise specified).
                      </li>
                      <li>
                        All prices displayed on the Site are subject to change
                        without notice and we reserve the right to correct, to
                        the maximum extent permitted by law, any errors
                        published on the Site. Prices for items in an Order are
                        those set out in your Order once that Order has been
                        confirmed and accepted by us in writing. Subsequent
                        price changes either up or down will not be
                        retroactively applied to confirmed and accepted Orders
                        unless we have agreed otherwise in writing.
                      </li>
                      <li>
                        Promotional discount codes may be given at our sole
                        discretion on terms and conditions notified at the time
                        of the relevant promotional offer or discount. We may
                        withdraw such discounts at any time at our absolute
                        discretion.
                      </li>
                      <li>
                        You agree to pay Delivery Costs as they are calculated
                        and listed in the Order confirmation at the time of
                        purchase.
                      </li>
                      <li>
                        International Delivery Costs do not include insurance or
                        any taxes or duties which may be applied by customs at
                        the destination country, and any duties or taxes
                        incurred are the responsibility of the person who placed
                        the Order. Where withholding taxes apply to any Order,
                        we reserve the right to gross-up such Order so that we
                        receive the same amount as if withholding taxes had not
                        applied, and Customer will pay the additional amounts
                        associated with such withholding taxes as a precondition
                        of us fulfilling the relevant Order.
                      </li>
                      <li>
                        In accordance with Australian export regulations we are
                        required to declare the exact value of all items and
                        identify the Order as dutiable "merchandise". We are
                        also prohibited by law from identifying an Order as a
                        "gift" for export purposes, even if you have placed the
                        Order with the intention of delivery to a gift recipient
                      </li>
                      <li>
                        To the maximum extent permitted by law, we reserve the
                        right to refuse a sale to any Customer or Business
                        Customer.
                      </li>
                    </ol>
                  </li>
                  <li>
                    PRODUCT SPECIFICATIONS
                    <ol>
                      <li>
                        Although we endeavour to provide accurate and complete
                        information on the Products and Services listed or
                        advertised on the Site, we cannot guarantee that the
                        information is up to date, accurate and complete at all
                        times. We reserve the right to (but are not obligated
                        to) make changes or updates to information displayed on
                        the Site at any time without notice to you.
                      </li>
                      <li>
                        Unless otherwise stated, any accessories, decorations or
                        furnishings shown in images of Products or Services on
                        the Site are not included.
                      </li>
                      <li>
                        All weights, heights and other measurements listed on
                        the Product specifications are provided for reference
                        and are approximate only.
                      </li>
                    </ol>
                  </li>
                  <li>
                    ORDERS
                    <ol>
                      <li>
                        You may place an Order by completing the Order form on
                        the Site and clicking the "confirm" button.
                      </li>
                      <li>
                        An Order is not accepted and legally binding on us until
                        we confirm by email (to your nominated email address as
                        listed in your Account) that:
                        <ol>
                          <li>
                            payment has been received for the Order, the
                            Delivery Costs and all other applicable fees, taxes
                            and charges; and
                          </li>
                          <li>the Order has been processed.</li>
                        </ol>
                      </li>
                      <li>
                        To the maximum extent permitted by law, Orders may not
                        be cancelled by you once they have been accepted by us,
                        unless as otherwise set out in clause 6.10.
                      </li>
                      <li>
                        We operate an online business and we will communicate
                        with you and visitors to our Site who make an enquiry
                        primarily via email. It is your responsibility to
                        therefore ensure that the correct contact details are
                        provided and that the nominated email address is
                        regularly checked for correspondence.
                      </li>
                      <li>
                        Each Order (once confirmed and accepted by us in
                        writing) represents a separate agreement between the
                        parties (and each separate Order placed by you on, or
                        via, the Site will be subject to this Agreement).
                      </li>
                      <li>
                        We reserve the right, at our absolute discretion, to:
                        <ol>
                          <li>
                            refuse to sell or to cancel Orders from Customers
                            that request commercial quantities of Products or
                            Services; or
                          </li>
                          <li>
                            reject and/or cancel your Order at any time prior to
                            dispatch of the Products, or the provision of the
                            Services, to you.
                          </li>
                        </ol>
                      </li>
                      <li>
                        If an Order has been cancelled, refused or cannot be met
                        due to unavailability of Products and/or Services, funds
                        paid in relation to that Order will be refunded in full
                        as soon as is reasonably practicable to the account from
                        which payment was made. You will be provided with email
                        acknowledgement of the cancellation and refund.
                      </li>
                      <li>
                        We accept no responsibility for Orders that are
                        declined, misplaced, not received or not accepted due to
                        disruptions caused to our internet connections or our
                        computer systems.
                      </li>
                      <li>
                        Where you have provided an incorrect or incomplete
                        delivery address for your Order and your Order is
                        returned to us, we may redeliver the Order at your
                        request and charge a redelivery fee for each subsequent
                        delivery attempt.
                      </li>
                      <li>
                        We do not guarantee the availability of any Products or
                        Services displayed or ordered on, or via, the Site.
                      </li>
                      <li>
                        After ordering online, you will receive an email
                        confirmation from containing an order reference and
                        order details. However, on account of the email
                        communication and the various filters that mail systems
                        use, we cannot guarantee receipt of the order
                        confirmation email. If you have email whitelisting
                        functionality, please add email address to ensure emails
                        are not sent to junk mail.
                      </li>
                      <li>Our products are delivered by Canada Post .</li>
                      <li>
                        You may receive multiple deliveries for your order and
                        these are described at the point of checkout.
                      </li>
                    </ol>
                  </li>
                  <li>
                    ORDERS
                    <ol>
                      <li>
                        You can pay for your Order using any of the methods
                        specified on the Site. A surcharge may apply to payments
                        made by credit card or third party payment providers
                        depending on the credit card or service used. We will
                        notify you of such surcharge at the time of payment.
                      </li>
                      <li>
                        You agree and acknowledge that we will treat an
                        electronic instruction as authentic and are under no
                        obligation to investigate the authenticity or authority
                        of persons issuing or transmitting such electronic
                        instructions, or to verify the accuracy and completeness
                        of such electronic instructions.
                      </li>
                      <li>
                        If your nominated payment method triggers our internal
                        suspect transaction protocols, we may contact you to
                        confirm additional details, or rescind the transaction.
                        In this case, until your Order has passed our fraud
                        prevention protocols your Order will not be fulfilled.
                        If you do not provide the requested information within 7
                        days, your Order will be cancelled and your payment will
                        be refunded back to you via the method in which you
                        paid. These information requests are sent to help
                        protect credit card holders from online fraud though we
                        provide no guarantee that we will identify any or all
                        instances of online fraud that may occur in relation to
                        any Order made on or via the Site.
                      </li>
                      <li>
                        This clause applies if you are using Stripe. Payment
                        processing services for Orders and/or Services are
                        provided by Stripe and are subject to the Stripe
                        Connected Account Agreement, which includes the Stripe
                        Terms of Service (collectively, the "Stripe Services
                        Agreement"). By placing an order and using Stripe to
                        process payments you agree to be bound by the Stripe
                        Services Agreement, which may be modified by Stripe from
                        time to time. As a condition of enabling payment
                        processing services through Stripe, you agree to provide
                        us with accurate and complete information about you and
                        your business, and you authorise us to share it and
                        transaction information related to your use of the
                        payment processing services provided by Stripe.
                      </li>
                      <li>
                        Customers must provide their exact billing address and
                        telephone number to our credit card processor - the
                        address and phone number your credit card bank has on
                        file for you. Incorrect information may cause a delay in
                        processing your Order.
                      </li>
                    </ol>
                  </li>
                  <li>
                    GIFT CARDS
                    <ol>
                      <li>
                        You are responsible for the use, safety and security of
                        your Gift Card. Gift Cards should be safely stored and
                        secured by you as if they were cash. Do not share your
                        account with anyone. If you believe your account has
                        been compromised please contact us at support@cardly.net
                        as soon as possible so we can put a hold on your
                        account. If someone has already spent your Gift Card,
                        code and/or voucher unfortunately we are unable to
                        assist you.
                      </li>
                      <li>
                        We have no obligation to replace or refund value for
                        lost, stolen, damaged or deleted Gift Cards except where
                        we have breached any condition or warranty implied under
                        the Australian Consumer Law that cannot be excluded in
                        these terms and conditions.
                      </li>
                      <li>
                        Our Gift Card Terms of Sale set out in clause 10 apply
                        when you purchase Gift Cards. These are subject to
                        change at any time at our discretion, and so the Terms
                        of Sale should be reviewed each time a purchase is made.
                      </li>
                    </ol>
                  </li>
                  <li>
                    GIFT CARDS TERMS OF SALE
                    <ol>
                      <li>
                        We is a re-seller of digital Gift Cards. We do not
                        re-sell plastic Gift Cards. To use this service you need
                        to create an account with Cardly or checkout as a guest.
                        To buy a Gift Card, code and/or voucher you will need to
                        use a valid VISA, MasterCard or American Express. Access
                        is provided with your email.
                      </li>
                      <li>
                        Features include (but are not limited to) buying,
                        sending and redeeming Gift Cards. processes payment
                        transactions on your behalf each time a payment is made.
                        We use a third party processor; we do not hold any
                        financial information or credit card details. provides
                        users with a platform to purchase digital Retailer Gift
                        Cards. The issuer of the Gift Card is the Retailer for
                        the applicable Gift Card and the terms governing use of
                        such card as set out on our Site for such Retailer Gift
                        Card and the relevant Retailer’s website is deemed
                        incorporated by reference into this Agreement.
                      </li>
                      <li>
                        Each time you send a Gift Card and the Gift Card has
                        been posted and redeemed, Cardly no longer accepts
                        responsibility for this Gift Card, code and/or voucher.
                        If you have entered the wrong mailing address and the
                        Gift Card has already been redeemed we are unable to
                        assist with this request.
                      </li>
                      <li>
                        If chosen, it is possible to send a Gift Card and allow
                        the recipient to swap for Retailer Gift Card(s) of their
                        choice to the equal value of the original Gift Card. The
                        redemption of a Gift Card is valid for 3 years. By
                        swapping the Gift Card for another Retailer Gift Card,
                        the user agrees to the Gift Card Terms and Conditions
                        set by the Retailer. Please check Retailers’ Terms and
                        Conditions before you perform the swap. Once a Gift Card
                        has been swapped or partially swapped, it cannot be
                        reverted back to the original Gift Card.
                      </li>
                      <li>
                        In no instance can a Gift Card, Retailer Card, swapped
                        Gift Card or Gift Card balance be refunded or redeemed
                        for cash. 
                      </li>
                    </ol>
                  </li>
                  <li>
                    GIFT CARD ACCOUNT
                    <ol>
                      <li>
                        When redeeming a Gift Card we will automatically create
                        an account for you. This is where all your Gift Cards
                        are stored and where you can check balances of Gift
                        Cards, view Retailer terms and conditions and Redeem
                        your Gift Cards.
                      </li>
                    </ol>
                  </li>
                  <li>
                    REDEMPTION OF GIFT CARDS
                    <ol>
                      <li>
                        To redeem a Gift Card you must first navigate and pass
                        the required security checks and validation. Upon
                        completion the chosen retailer gift card will be emailed
                        and added to your account.
                      </li>
                      <li>
                        To redeem the retailer voucher follow the instructions
                        provided.
                      </li>
                      <li>
                        To redeem in store, print out or store the gift card PDF
                        on your device. Simply present the print out or the PDF
                        on your device to the retailer at the time of purchase.
                      </li>
                      <li>
                        To redeem online, at checkout, on the retailers website,
                        paste the code from the Gift Card into the designated
                        code area on that Retailers website. Please note: not
                        all Retailers Gift Cards are available for purchasing
                        their respective goods online. Please check each
                        Retailer’s Terms and Conditions before you purchase a
                        Gift Card.
                      </li>
                    </ol>
                  </li>
                  <li>
                    BUSINESS CUSTOMERS
                    <ol>
                      <li>
                        This clause 13 only applies if you are an approved
                        Business Customer.
                      </li>
                      <li>
                        To be viewed as a Business Customer and offered access
                        to a business account the following process applies:
                        <ol>
                          <li>
                            please submit your details
                            to  and
                          </li>
                          <li>
                            We will review your application, and notify you of
                            the outcome of our review.
                          </li>
                        </ol>
                      </li>
                      <li>
                        We reserve sole and exclusive right to determine
                        Business Customers.
                      </li>
                      <li>
                        Business Customers will be offered the ability to
                        purchase credits which will allow them to pre-pay for
                        Products at the rates notified by Cardly at the time of
                        purchase ("Credit").
                      </li>
                      <li>
                        Each Credit can be used by a Business Customer to Order
                        a single card or a single letter offered as a Product on
                        the Site and, unless otherwise notified by Cardly,
                        includes the cost of postage of such single item to the
                        US, UK, CA or AU.
                      </li>
                      <li>
                        A Credit includes the cost of artwork supplied or its
                        third party artists, unless otherwise notified.
                      </li>
                      <li>
                        Once used to Order a Product, the used Credit expires.
                      </li>
                      <li>
                        To the maximum extent permitted by law, Credit:
                        <ol>
                          <li>
                            are only valid for 12 months from date of purchase
                            and any unused Credit will expire on the first
                            anniversary of their purchase date;
                          </li>
                          <li>will not be refunded </li>
                          <li>
                            are not transferable, nor redeemable for cash.
                          </li>
                        </ol>
                      </li>
                      <li>
                        Where a Business Customer has insufficient Credit to
                        complete an Order, the cost of such Orders will be the
                        full price advertised on the Site.
                      </li>
                      <li>
                        Business Customers will be offered the ability to
                        purchase gift credits which will allow them to pay for
                        gift cards at the rates notified at the time of purchase
                        ("Gift Credit").
                      </li>
                      <li>
                        Gift Credit can be used by a Business Customer to Order
                        gift cards on the Site and, unless otherwise notified by
                        Cardly.
                      </li>
                      <li>
                        Gift Credit has the same momentary value as the Business
                        Customer’s currency of choice.
                      </li>
                      <li>
                        Once Gift Credit is used to Order a gift card, the used
                        Gift Credit expires.
                      </li>
                      <li>
                        To the maximum extent permitted by law, Gift Credit:
                        <ol>
                          <li>
                            is only valid for 12 months from date of purchase
                            and any unused Gift Credit will expire on the first
                            anniversary of their purchase date;
                          </li>
                          <li>will not be refunded ; and</li>
                          <li>is not transferable, nor redeemable for cash.</li>
                        </ol>
                      </li>
                      <li>
                        Where a Business Customer has insufficient Gift Credit
                        to complete a gift card order, the cost of such orders
                        will be the full price advertised on the Site.
                      </li>
                    </ol>
                  </li>
                  <li>
                    SUBSCRIPTIONS
                    <ol>
                      <li>
                        Business Customers will be offered the ability to
                        subscribe to extra features and functions at the rates
                        notified at the time of purchase ("Subscriptions").
                      </li>
                      <li>
                        To the maximum extent permitted by law, Subscriptions:
                        <ol>
                          <li>
                            are only valid for the period of subscription;
                          </li>
                          <li>will not be refunded; and</li>
                          <li>
                            are not transferable, nor redeemable for cash.
                          </li>
                        </ol>
                      </li>
                      <li>
                        The term of Subscription shall automatically renew for
                        the same term period as the term indicated when
                        purchased, unless the Business Customer notifies of
                        their intent not to renew prior to the expiration of the
                        then-current term.
                      </li>
                      <li>
                        Upon termination of a Subscription, the Business
                        Customer shall no longer access the features and
                        functions of the Subscription and shall not circumvent
                        any security mechanisms contained therein.
                      </li>
                      <li>
                        One Subscription is required per Business Customer
                        account
                      </li>
                    </ol>
                  </li>
                  <li>
                    ADDRESS VALIDATION
                    <ol>
                      <li>
                        A Business Customer can request to validate addresses if
                        they have a current Subscription and the relevant
                        Subscription tier ("Address Validation").
                      </li>

                      <li>
                        We uses best endeavours to validate the accuracy of
                        addresses provided by a Business Customer and that
                        validation is reliant on external data source and 3rd
                        parties.
                      </li>
                      <li>
                        It is the responsibility of the Business Customer to
                        review the results of Address Validation and perform
                        additional validation where is unable to determine a
                        final address.
                      </li>
                      <li>
                        Address Validation must not be abused and reserves the
                        right, to the maximum extent permitted by law, to
                        prevent abuse and excessive use of Address Validation.
                        This includes, but is not limited to, placing limits on
                        the Address Validation usage.
                      </li>
                    </ol>
                  </li>
                  <li>
                    FILE STORAGE
                    <ol>
                      <li>
                        A Business Customer can request to transfer and store
                        their data via a file transfer function if they have a
                        current Subscription and the relevant tier ("File
                        Storage").
                      </li>

                      <li>
                        Data stored using File Storage remains the property of
                        the Business Customer.
                      </li>
                      <li>
                        The Business Customer can delete data stored via File
                        Storage at any time at their discretion.
                      </li>
                      <li>
                        We uses best endeavours to store and secure File Storage
                        data using encryption.
                      </li>
                    </ol>
                  </li>
                  <li>
                    CREDIT EXPIRY EXTENSION
                    <ol>
                      <li>
                        A Business Customer’s credits will extend beyond the
                        limits detailed in clause 12.8(a) if they have a current
                        Subscription and the relevant tier ("Credit Expiry
                        Extension").
                      </li>
                      <li>
                        A Business Customer must maintain a continuous
                        Subscription at the relevant tier  to qualify for a
                        Credit Expiry Extension.
                      </li>
                      <li>
                        A period of 1 month after a Subscription has expired
                        will be provided as a grace period to re-enable the
                        Subscription and continue to extend credits from their
                        original expiry.
                      </li>
                    </ol>
                  </li>
                  <li>
                    GIFT CREDIT RECOVERY
                    <ol>
                      <li>
                        A Business Customer can recover an agreed percentage of
                        the original Gift Credit used for gift cards that are
                        not redeemed after 18 months if they have a current
                        Subscription and the relevant tier ("Gift Credit
                        Recovery").
                      </li>
                      <li>
                        A Business Customer must maintain a continuous
                        Subscription at the relevant tier to qualify for a Gift
                        Credit Recovery.
                      </li>
                      <li>
                        The percentage of Gift Credit recovered, is the
                        percentage outlined in the relevant subscription tier,
                        and is multiplied against the original value of Gift
                        Credit used in the original purchase.
                      </li>
                      <li>
                        Gift Credit recovered will be added back to the Gift
                        Credit of the Business Customer. Gift Credit is not
                        transferable, nor redeemable for cash.
                      </li>
                    </ol>
                  </li>
                  <li>
                    WHITE GLOVE SUPPORT
                    <ol>
                      <li>
                        A Business Customer is able to access and receive
                        support using Cardly if they have a current Subscription
                        and the relevant tier ("White Glove Support").
                        <ol>
                          <li>
                            White Glove support will include support with
                            artwork, templates, contact lists, campaigns,
                            automations, user management and other support as
                            requested. We will actively perform tasks as
                            requested, assist with training and other types of
                            support that are reasonable.
                          </li>
                        </ol>
                      </li>
                      <li>Support will be provided during Business Hours.</li>
                      <li>
                        Support requests from Business Customers with White
                        Glove Support will be responded to in no less than one
                        business day.
                      </li>
                      <li>
                        White Glove Support must not be abused and reserves the
                        right, to the maximum extent permitted by law, to
                        prevent abuse and excessive use of White Glove Support.
                        This includes, but is not limited to, placing limits on
                        the hours of support provided.
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { TermsConditionsPage }
