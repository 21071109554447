import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { GiftCardDetailPage } from './pages/GiftCardDetailPage'
import { FaqPage } from './pages/FaqPage'
import { LoginPage } from './pages/LoginPage'
import { SingupPage } from './pages/SingupPage'
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage'
import { DisclaimerPage } from './pages/DisclaimerPage'
import { TermsConditionsPage } from './pages/TermsConditionsPage'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'

/**
 * In this project, I decided to use scss, so that I could easily type it
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='gift-card/:cardId' element={<GiftCardDetailPage />} />
         
          <Route path='faq' element={<FaqPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='singup' element={<SingupPage />} />
          <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='disclaimer' element={<DisclaimerPage />} />
          <Route path='terms-conditions' element={<TermsConditionsPage />} />
          <Route path='*' element={<NotfoundPage />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
