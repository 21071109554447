import React from 'react'
import { Input } from '../components/Input'
import { Textarea } from '../components/Textarea'
import { NavLink } from 'react-router-dom'
function LoginPage() {
  return (
    <>
      <div className='auth'>
        <div className='container-left'>
          <div className='auth__inner'>
            <form className='auth-form'>
              <div className='auth-form-title'>Welcome back!</div>
              <div className='auth-form-text'>
                Enter your Credentials to access your account
              </div>
              <div className='auth-form-input'>
                <div className='auth-form-input-item'>
                  <div className='auth-form-input-item-label'>
                    <label>Email address</label>
                  </div>
                  <input type='email' placeholder='Enter your email' />
                </div>
                <div className='auth-form-input-item'>
                  <div className='auth-form-input-item-label'>
                    <label>Password</label>
                    <NavLink>forgot password</NavLink>
                  </div>
                  <input type='text' placeholder='Name' />
                </div>
              </div>
              <div className='auth-form-checkbox'>
                <input type='checkbox' id='days' />
                <label htmlFor='days'>Remember for 30 days</label>
              </div>
              <button className='auth-form-btn'>Login</button>
              <div className='auth-form-link'>
                Don’t have an account? <NavLink to={'/singup'}>Sign Up</NavLink>
              </div>
            </form>
            <div className='auth-img'>
              <img src={require(`../assets/img/login-bg.jpg`)} alt='' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { LoginPage }
