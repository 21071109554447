import React from 'react'
import { NavLink } from 'react-router-dom'
function GiftCard({ page, title, img, price }) {
  return (
    <NavLink to={`/gift-card/${page}`} className='cards-item'>
      <div className='cards-item-title'>{title}</div>
      <div className='cards-item-img'>
        <img src={require(`../assets/img/cards/${img}`)} alt='' />
      </div>
      <div className='cards-item-price'> ${price[0]} - ${price[price.length - 1]} CAD</div>
    </NavLink>
  )
}

export { GiftCard }
