import React from 'react'
import { Input } from '../components/Input'
import { Textarea } from '../components/Textarea'

function DisclaimerPage() {
  return (
    <>
      <div className='rules'>
        <div className='container'>
          <div className='rules__inner'>
            <h2>Disclaimers</h2>
            <div className='rules-info'>
              <div className='rules-info-text'>
                <p>Disclaimer for Card gifty</p>
                <p>
                  If you require any more information or have any questions
                  about our site's disclaimer, please feel free to contact us by
                  email.
                </p>
              </div>
              <div className='rules-info-text'>
                <p>Disclaimers for Card gifty Pty Ltd</p>
                <p>
                  All the information on this website is published in good faith
                  and for general information purpose only. Website Name does
                  not make any warranties about the completeness, reliability
                  and accuracy of this information. Any action you take upon the
                  information you find on this website , is strictly at your own
                  risk. will not be liable for any losses and/or damages in
                  connection with the use of our website.
                </p>
                <p>
                  From our website, you can visit other websites by following
                  hyperlinks to such external sites. While we strive to provide
                  only quality links to useful and ethical websites, we have no
                  control over the content and nature of these sites. These
                  links to other websites do not imply a recommendation for all
                  the content found on these sites. Site owners and content may
                  change without notice and may occur before we have the
                  opportunity to remove a link which may have gone ‘bad'.
                </p>
                <p>
                  Please be also aware that when you leave our website, other
                  sites may have different privacy policies and terms which are
                  beyond our control. Please be sure to check the Privacy
                  Policies of these sites as well as their "Terms of Service"
                  before engaging in any business or uploading any information.
                </p>
              </div>
              <div className='rules-info-text'>
                <p>Consent</p>
                <p>
                  By using our website, you hereby consent to our disclaimer and
                  agree to its terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { DisclaimerPage }
