import React from 'react'
import { Input } from '../components/Input'
import { Textarea } from '../components/Textarea'

function PrivacyPolicyPage() {
  return (
    <>
      <div className='rules'>
        <div className='container'>
          <div className='rules__inner'>
            <h2>Privacy Policy</h2>
            <div className='rules-info'>
              <div className='rules-info-text'>
                <span>PERSONAL INFORMATION</span>
                <p>
                  This section of the Privacy Policy applies to the collection
                  of personal information .
                </p>
                <p>
                  We are committed to the protection of your PI and meeting the
                  standards set out in the Privacy Act 1988 (Cth) (“the Act”)
                  and the National Privacy Principals (“NPPs”). This policy sets
                  out how we handle your PI and the rights and obligations that
                  we both have in relation to that information.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>COLLECTION</span>
                <p>
                  You can generally visit this Website without telling us who
                  you are or revealing other PI and we will not collect any PI
                  about you except when you knowingly provide it. The types of
                  information that we collect from you will depend on the
                  circumstances of collection and on the type of service you
                  request from us, for example:
                </p>
                <ul>
                  <li>
                    when you request products from us, we may collect details
                    such as your name, address, company details, billing
                    address, shipping address, credit card information, email
                    and phone contacts, etc.
                  </li>
                  <li>
                    when you are applying for a position with us we may collect
                    any information required in the course of our recruitment
                    process such as your resume, contact details, skills,
                    qualifications, languages spoken, residency status,
                    referees, etc.
                  </li>
                </ul>
              </div>
              <div className='rules-info-text'>
                <span>HOW WE COLLECT PERSONAL INFORMATION</span>
                <p>
                  We primarily collect PI directly from you through electronic,
                  written and/or verbal means of communication when you interact
                  with us for example when:
                </p>
                <ul>
                  <li>you place an order with us.</li>
                  <li>you send us an email or post an entry on our website.</li>
                  <li>
                    you submit an entry into a competition or trade promotion.
                  </li>
                </ul>
              </div>
              <div className='rules-info-text'>
                <span>LINKS</span>
                <p>
                  The Card gifty website may contain links to other sites which
                  may be of interest to you. Linked websites are responsible for
                  their own privacy practices and you should check those
                  websites for their respective privacy statements. We are not
                  responsible for the privacy practices or the content of such
                  websites.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>USE</span>
                <p>We will only use the personal information we collect:</p>
                <ul>
                  <li>
                    for the main purpose(s) for which it was disclosed at the
                    time of collection.
                  </li>
                  <li>where you have consented to the use or disclosure.</li>
                </ul>
              </div>
              <div className='rules-info-text'>
                <p>
                  Generally we will use and disclose your personal information
                  for a number of purposes including but not limited to the
                  following purposes:
                </p>
                <ul>
                  <li>
                    to provide the products or services you have requested.
                  </li>
                  <li>to answer your enquiry.</li>
                  <li>
                    to facilitate the running of and your participation in trade
                    promotions and other competitions.
                  </li>
                  <li>
                    for direct marketing of products or services and to keep you
                    informed of new developments we believe may be of interest
                    to you.
                  </li>
                  <li>
                    to establish and maintain your relationship as an applicant
                    for a position, including providing you with interviews,
                    reference checking, etc.
                  </li>
                </ul>
                <p>
                  If you do not wish to receive marketing or other communication
                  from us, please contact us on the details below so that we can
                  update your preferences.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>DISCLOSURE</span>
                <p>
                  We may disclose PI across the Card gifty group of companies
                  and our related bodies corporate (in accordance with this
                  Privacy Policy). We may also provide PI to:
                </p>
                <ul>
                  <li>
                    third parties engaged by us to provide functions on our
                    behalf such as mailouts, debt collection, and advertising;
                    and
                  </li>
                  <li>
                    third parties authorised by you to receive PI held by us.
                  </li>
                </ul>
                <p>
                  We may also disclose PI to unrelated third parties (for
                  example, our professional advisors and government authorities
                  or agencies) where the disclosure is reasonably required to
                  obtain advice, prepare for legal proceedings, investigate
                  suspected improper conduct or wrong doing, to assist a lawful
                  authority in the discharge of its duties and/or by law.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>DATA QUALITY</span>
                <p>
                  We take reasonable steps to ensure that your PI is accurate,
                  complete and up-to-date whenever we collect or use it. If the
                  PI we hold about you is inaccurate, incomplete or out-of-date,
                  please contact us and we will take reasonable steps to correct
                  this information.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>DATA SECURITY</span>
                <p>
                  We will take reasonable steps to maintain the security of and
                  to prevent unauthorised access to or disclosure of your PI.
                  However, we do not guarantee that unauthorised access to your
                  PI will not occur, either during transmission of that
                  information to us or after we receive that information. Once
                  we no longer need your PI, we will take all reasonable steps
                  to de-identify your PI.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>OPENNESS</span>
                <p>
                  If you have any questions on the handling of your PI or the
                  kind of PI we hold about you please contact at the details
                  below and we will endeavour to assist you with your request.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>ACCESS AND CORRECTION</span>
                <p>
                  You have a right to access your PI, subject to some exceptions
                  allowed by law. If you would like to do so, please let us know
                  by contacting our Privacy Officer. For security reasons, we
                  may ask you to put your request in writing.
                </p>
                <p>
                  We may charge a fee for searching for and providing access to
                  your information.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>IDENTIFIERS</span>
                <p>
                  As required by the NPPs, we do not collect or use Commonwealth
                  government identifiers when interacting with you.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>ANONYMITY</span>
                <p>
                  Where practical, you may deal with us on an anonymous basis
                  however, if you do not provide us with your PI we we may not
                  be able to provide you with the requested product or service
                  (for example, if we require your details in connection with a
                  competition we are running, we cannot provide you with a prize
                  should you be a prize winner and we cannot contact you).
                </p>
              </div>
              <div className='rules-info-text'>
                <span>OVERSEAS TRANSFER OF INFORMATION</span>
                <p>Your PI will be encrypted and stored in Australia. </p>
                <p>
                  We will not transfer your PI to other parties outside
                  Australia in any circumstances, unless we obtain agreement
                  from the recipient to protect your information in accordance
                  with Australian Privacy Laws including to abide by the terms
                  of this policy or we have your consent.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>SENSITIVE INFORMATION</span>
                <p>
                  We will not require you to provide sensitive information
                  unless: 
                </p>
                <ul>
                  <li>you have consented; or</li>
                  <li>
                    the collection of the information is specifically authorised
                    or required by law.
                  </li>
                </ul>
              </div>
              <div className='rules-info-text'>
                <span>CHANGES TO OUR PRIVACY POLICY</span>
                <p>
                  From time to time, we may need to change this Privacy Policy
                  to reflect our changing business practices. We may therefore
                  change this Privacy Policy at any time by posting the changed
                  Privacy Policy on this website.
                </p>
                <p>
                  All PI collected and held by us will be governed by our most
                  recent Privacy Policy, as posted on this website.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>NON-PERSONAL INFORMATION</span>
                <p>
                  This section of the Privacy Policy applies to the collection
                  of non-personal information by or on behalf of Card gifty Pty
                  Ltd ACN 605 282 255 (“Card gifty”).
                </p>
                <p>
                  For example, most information collected when you visit our
                  website is statistical and not linked to you personally. This
                  can include information about pages visited, software versions
                  used, device identifiers (like IP address), referring
                  websites, preferences you choose and other activities such as
                  links clicked.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>HOW WE USE IT</span>
                <p>
                  We use the non-personal information we collect to understand
                  and improve the performance of our website.
                </p>
                <p>
                  We use Google Analytics including Remarketing with Google
                  Analytics and Google Analytics Demographics and Interest
                  Reporting to understand how our services are used and who uses
                  them. We make no attempt to identify you using this
                  information, and we do not pass on this information to other
                  third parties. If you would prefer to opt out of providing
                  this information, you may use the Google Analytics Opt-out
                  Browser Add-on.
                </p>
              </div>
              <div className='rules-info-text'>
                <span>CONTACT US</span>
                <p>
                Any queries about this Privacy Policy, our Terms of Service or other policy related material can be directed to our support staff .
                </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PrivacyPolicyPage }
