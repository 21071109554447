import React, {useState} from 'react'
import faqData from '../data/faqData.json'
import { ReactComponent as FaqArrow } from '../assets/img/svg/faq-arrow.svg'
function FaqPage() {

  const [active, setActive] = useState(0);
  return (
    <>
      <div className='faq'>
        <div className='container'>
          <div className='faq__inner'>
            <h2>Frequently Asked Questions</h2>
            <div className='faq-items'>
              {faqData.map((item, idx) => {
                const isActive = active === idx;
                return (
                  <div key={item.id} className={`faq-item ${isActive ? "active" : ""}`}>
                    <div className='faq-item-head' onClick={() => setActive(idx)}>
                      <div className='faq-item-head-title'>{item.title}</div>
                      <div className='faq-item-head-icon'>
                        <FaqArrow />
                      </div>
                    </div>
                    <div className='faq-item-text'>
                      {item.text.map((item, idx) => {
                        return <p key={idx}>{item}</p>
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { FaqPage }
