import React, { useState, useRef } from 'react'
import { useOutsideClick } from '../hooks/useOutsideClick'
import { NavLink, useLocation } from 'react-router-dom'

/**
 * Here I have used the following approach for svg, so that it is possible to edit them
 */
import { ReactComponent as Logo } from '../assets/img/svg/logo.svg'


function Header() {
  const location = useLocation()

  const [isActiveMenuBtn, setActiveMenuBtn] = useState(false)


  /**
   * Switch for hamburger menu
   */
  const handleToggleMenuBtn = () => {
    setActiveMenuBtn(!isActiveMenuBtn)
    document.body.classList.toggle('hiden')
  }


  return (
    <>
      <header className={`header ${location.pathname === '/' ? 'header--home' : ''}`}>
        <div className='container'>
          <div className={`header__inner ${isActiveMenuBtn ? 'active' : ''}`}>
            <div className='header__inner-logo'>
              <NavLink to='/'>
                <Logo />
              </NavLink>
            </div>
            <nav
              className={`header__inner-nav ${isActiveMenuBtn ? 'active' : ''}`}
            >
              <ul className='nav-list'>
                <li className='nav-list-item'>
                  <NavLink to='/'>Gift Cards</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/terms-conditions'>Terms & Conditions</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/disclaimer'>Disclaimer</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/faq'>FAQs</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/contact'>Contact</NavLink>
                </li>
              </ul>
            
            </nav>
            <div
              className={`header__menu-btn ${isActiveMenuBtn ? 'open' : ''}`}
              onClick={handleToggleMenuBtn}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export { Header }
