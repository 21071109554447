import React from 'react'

function NotfoundPage() {
  return (
    <div className='notfound'>
      <div className='container'>
        <div className='notfound__inner'>
          <h2 className='notfound-title'>Notfound Page</h2>
        </div>
      </div>
    </div>
  )
}

export { NotfoundPage }
