import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import cardsData from '../data/cardsData.json'
import { NavLink } from 'react-router-dom'
function GiftCardDetailPage() {
  const [active, setActive] = useState(null)

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null)
    } else {
      setActive(index)
    }
  }
  const { cardId } = useParams()
  const thisCar = cardsData.find((prod) => String(prod.id) === cardId)
  return (
    <>
      <div className='breadcrumbs'>
        <div className='container'>
          <div className='breadcrumbs__inner'>
            <NavLink to={'/'}>Home</NavLink>
            <NavLink to={'/'}>Gift Cards</NavLink>
            <span>{thisCar.title}</span>
          </div>
        </div>
      </div>
      <div className='gift-card'>
        <div className='container'>
          <div className='gift-card__inner'>
            <div className='gift-card-box'>
              <div className='gift-card-box-img'>
                <div className='gift-card-box-img-box'>
                  <img
                    src={require(`../assets/img/cards/${thisCar.img}`)}
                    alt=''
                  />
                </div>
              </div>
              <div className='gift-card-box-info'>
                <h2 className='gift-card-box-info-title'>Indigo Gift Card</h2>
                <div className='gift-card-box-info-price'>
                  ${thisCar.price[0]} - $
                  {thisCar.price[thisCar.price.length - 1]} CAD
                </div>
                <div className='gift-card-box-info-label'>No expiration</div>
                <div className='gift-card-box-info-pricelist'>
                  <div className='gift-card-box-info-pricelist-title'>
                    Select how much you'd like to send:
                  </div>
                  <div className='gift-card-box-info-pricelist-items'>
                    {thisCar.price.map((item, idx) => {
                      return (
                        <button
                          key={idx}
                          className={`gift-card-box-info-pricelist-item ${
                            active === idx + 1 ? 'active' : ''
                          }`}
                          onClick={() => handleToggle(idx + 1)}
                        >
                          {item}$
                        </button>
                      )
                    })}
                  </div>
                </div>
                <div className='gift-card-box-info-description'>
                  {thisCar.description}
                </div>
                <NavLink className={'gift-card-box-info-btn'}>
                  PICK A CARD
                </NavLink>
              </div>
            </div>
            <div className='gift-card-listinfo'>
              <div className='gift-card-listinfo-title'>
                Terms & Conditions
                <span></span>
              </div>
              <ul>
                {thisCar.termsConditions
                  ? thisCar.termsConditions.map((item, idx) => {
                      return <li key={idx}>{item}</li>
                    })
                  : null}
              </ul>
            </div>
            <div className='gift-card-listinfo'>
              <div className='gift-card-listinfo-title'>
                Redemption Information
                <span></span>
              </div>
              <ul>
                {thisCar.redemptionInformation
                  ? thisCar.redemptionInformation.map((item, idx) => {
                      return <li key={idx}>{item}</li>
                    })
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { GiftCardDetailPage }
