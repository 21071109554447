import React from 'react'
import { GiftCard } from '../components/GiftCard'
import cardsData from '../data/cardsData.json'
function HomePage() {
  return (
    <>
      <div className='giftpass'>
        <img src={require(`../assets/img/giftpass-bg.jpg`)} alt='' />
        <h1>Give a GIFTPASS</h1>
        <p>
          offers a huge range of Canadian gift cards perfect for every occasion.
          Whether it's a birthday, Christmas present, wedding gift, baby shower
          or whatever reason you might have to celebrate, we're sure you'll find
          the perfect gift card to include in one of our personalised greeting
          cards. Send the DIY nut a Home Depot gift card, spoil the kids with
          a Cineplex movie experience, or let them enjoy a delicious night out
          with the Ultimate Dining gift card.
        </p>
      </div>
      <div className='cards'>
        <div className='container'>
          <div className='cards__inner'>
            <h2>Shop from Hundreds of Gift Cards</h2>
            <p>
              Buy, Send & Claim Gift Cards. Chip in with Friends. Store & Manage
              Gift Cards.
            </p>
            <div className='cards-items'>
              {cardsData.map((item) => {
                return (
                  <GiftCard
                    key={item.id}
                    title={item.title}
                    img={item.img}
                    price={item.price}
                    page={item.id}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='subscribe'>
        <div className='container'>
          <div className='subscribe__inner'>
            <div className='subscribe-label'>KEEP IN TOUCH</div>
            <h2>We'll keep you posted</h2>
            <div className='subscribe-text'>
              Keep up to date with our latest offers, updates and products.
            </div>
            <form className='subscribe-form'>
              <div className='subscribe-form-row'>
                <input type='text' placeholder='Enter your name' />
                <input type='email' placeholder='Enter your email' />
              </div>
              <button className='button' type='submit'>SUBSCRIBE</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export { HomePage }
